import { PageProps } from 'gatsby';
import React, { FC } from 'react';

import Content from '../components/Content';
import Layout from '../components/Layout';

const IndexPage: FC<PageProps> = function ({ location: { pathname } }) {
  return (
    <Layout pathname={pathname} showTitle title="pages.a-propos.title">
      <Content size="md">
        <p>
          Modern Eye Gallery is a family business based in North of France near
          Lille for more than 40 years.
        </p>
        <p>
          We have a very large inventory with thousands of pieces from 18th
          century to 20th century with a specialization for 20th century and
          neoclassical style chic furniture.
        </p>
        <p>
          With an experience of 20 years selling worldwide, We are used to pack
          and ship our pieces everywhere in the world with full value insurance.
        </p>
        <p>
          We are happy to answer any request you may have by email, by phone or
          to receive you with great pleasure in our showroom if you are nearby.
        </p>
      </Content>
    </Layout>
  );
};

export default IndexPage;
